import pr1 from '../../img/dashbord/image00003.jpeg';
import pr2 from '../../img/dashbord/image00004.jpeg';
import pr3 from '../../img/dashbord/image00002.jpeg';
import { Link } from 'react-router-dom';
import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';



const Projets = () => {

    const about = useRef(null); // Ref for the first title
    // const images = useRef(null); // Ref for the second title
    const box1 = useRef(null); // Ref for the second title
    const box2 = useRef(null); // Ref for the second title
    const box3 = useRef(null); // Ref for the second title


    gsap.registerPlugin(ScrollTrigger); // Register the ScrollTrigger plugin

    useEffect(() => {
        // const tl = gsap.timeline();
        const titelElement = about.current;
        const boxx1 = box1.current;
        const boxx2 = box2.current;
        const boxx3 = box3.current;

        // const image = images.current;

        // First animation for the first title (no scroll trigger, animate immediately)
        // gsap.fromTo(
        //     titelElement,
        //     { opacity: 0, y: 100 },
        //     { opacity: 1, y: 0, duration: 1 }
        // );

        // Second animation for the second title, triggered by scrolling
        gsap.fromTo(
            titelElement,
            { opacity: 0, y: 100 }, // Initial state
            {
                opacity: 1,
                y: 0,
                duration: 1,
                scrollTrigger: {
                    trigger: titelElement, // Element to trigger the animation
                    start: "top bottom",    // Start when the top of the element hits the center of the viewport
                    toggleActions: "play none restart none" // Play the animation when triggered
                }
            }
        );

        gsap.fromTo(
            boxx1,
            { opacity: 0, width : 0 , height : 0 , transformOrigin: "center center" }, // Initial state
            {
                opacity: 1,
                width : '100%' ,
                height : '100%',
                duration: 1,
                
                scrollTrigger: {
                    trigger: boxx1, // Element to trigger the animation
                    start: "top bottom", 
                    scrub: 1,   // Start when the top of the element hits the center of the viewport
                    toggleActions: "play none restart none" // Play the animation when triggered
                    
                }
            }
        );
        gsap.fromTo(
            boxx2,
            { opacity: 0, width : 0 , height : 0 , transformOrigin: "center center" }, // Initial state
            {
                opacity: 1,
                width : '100%' ,
                height : '100%',
                duration: 1,
                scrollTrigger: {
                    scrub: 1,
                    trigger: boxx2, // Element to trigger the animation
                    start: "top bottom",    // Start when the top of the element hits the center of the viewport
                    toggleActions: "play none restart none" // Play the animation when triggered
                }
            }
        );
        gsap.fromTo(
            boxx3,
            { opacity: 0, width : 0 , height : 0 , transformOrigin: "center center" }, // Initial state
            {
                opacity: 1,
                width : '100%' ,
                height : '100%',
                duration: 1,
                scrollTrigger: {
                    scrub: 1,
                    trigger: boxx3, // Element to trigger the animation
                    start: "top bottom",    // Start when the top of the element hits the center of the viewport
                    toggleActions: "play none restart none" // Play the animation when triggered
                }
            }
        );

    }, []);

    return (
        <div id='Ser' className="h-fit md:w-[1317px] w-[390px] mx-auto md:mt-[139px] flex flex-col items-start p-5 md:p-0 ">
            <p className='text-center text-[25px] md:text-[50px] font-Poppins font-bold px-3 md:px-0  md:leading-[39px] text-[#218555] max-w-[1280px] mx-auto my-16 uppercase  ' ref={about} >Nos Projets</p>
            {/* <div className="m-0 flex flex-col items-start">
                <p className="md:text-[60px] text-[40px] font-bold md:leading-[73px] leading-[63px] text-start text-black font-inter" >Nos Services</p>
                <div className="md:mt-2 mt-1 md:w-[400px] w-[250px] h-0 md:border-[5px] border-[3px] border-[#F98B2A]"></div>
            </div> */}
            <Link ref={box1} to={'/Hydroponie'} className='w-full shadow-2xl' >
                <div className=" md:h-[408px] h-[225px] bg-black md:mt-[28px] mt-[18px] relative flex items-center " style={{ backgroundImage: `url(${pr1})`, backgroundSize: 'cover', backgroundPosition: 'center' }} >
                    <div className="w-full h-full absolute top-0 z-10 bg-[#F98B2A] opacity-20"></div>
                    <p className="md:text-[60px] text-[20px] font-bold md:leading-[73px] leading-[25px] text-center text-[#FFFFFF] font-inter m-auto z-20 " >Hydroponie</p>
                </div>
            </Link>
            <Link ref={box2} to={'/Compostage'} className='w-full shadow-2xl' >
                <div className="  md:h-[408px] h-[225px] bg-black md:mt-[28px] mt-[18px] relative flex items-center " style={{ backgroundImage: `url(${pr2})`, backgroundSize: 'cover', backgroundPosition: 'center' }} >
                    <div className="w-full h-full absolute top-0 z-10 bg-[#F98B2A] opacity-20"></div>
                    <p className="md:text-[60px] text-[20px] font-bold md:leading-[73px] leading-[25px] text-center text-[#FFFFFF] font-inter m-auto z-20 " >Compostage et gestion des déchets organiques</p>
                </div>
            </Link>
            <Link ref={box3} to={'/Agriculture'} className=' w-full shadow-2xl' >
                <div className="md:h-[408px] h-[225px] bg-black md:mt-[28px] mt-[18px] relative flex items-center " style={{ backgroundImage: `url(${pr3})`, backgroundSize: 'cover', backgroundPosition: 'center' }} >
                    <div className="w-full h-full absolute top-0 z-10 bg-[#F98B2A] opacity-20"></div>
                    <p className="md:text-[60px] text-[20px] font-bold md:leading-[73px] leading-[25px] text-center text-[#FFFFFF] font-inter m-auto z-20  " >Agriculture de Précision par Drones</p>
                </div>
            </Link>


        </div>
    );
}

export default Projets;