// import footimg from '../img/footer.jpg';
import footimg from '../img/dashbord/aboutus5.jpg';
import istagram from '../img/insticon.png';
import face from '../img/faceicon.png';
import linkden from '../img/linkedin.png';
import wahtasap from '../img/wahtasap.png';
import phone from '../img/phone.png';
import ema from '../img/email.png';
import adr from '../img/adr.png';



const Footer = () => {

    

    return (
        <div className="md:w-[1317px] w-[375px] md:h-[440px] h-[250px] mx-auto mt-[39px] mb-[39px] bg-black " style={{ backgroundImage: `url(${footimg})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundAttachment: 'fixed' }} >
            <div className="relative w-full h-full  ">
                {/* <div className="w-full h-full  bg-[#F98B2A] opacity-50 absolute top-0 z-10 "></div> */}
                <div className="w-full h-full md:px-[39px] px-[10px] absolute top-0 z-20 flex items-center justify-between " >
                    <p className="font-inter font-bold md:text-[70px] text-[25px] md:leading-[85px] leading-[35px] text-white mr-3" >Regional.<br />Tasteful.<br />Sustainable.</p>
                    <div className="">
                        <p className="font-inter font-bold md:text-[35px] text-[10px] md:leading-[35px] leading-[20px] text-white md:mb-5 " >The Richness of Moroccan Lands <br /> at Your Fingertips</p>
                        <div className=" flex items-center ">
                            <img src={phone} className=' md:w-4 w-2 mr-2 ' alt="" />
                            <p className="font-normal  md:text-[17px] text-[10px]  md:leading-[35px] leading-[20px] text-white font-inter " >+212662385218</p>
                        </div>
                        <div className=" flex items-center ">
                            <img src={phone} className=' md:w-4 w-2 mr-2 ' alt="" />
                            <p className="font-normal  md:text-[17px] text-[10px]  md:leading-[35px] leading-[20px] text-white font-inter" >+212619861440</p>
                        </div>
                        <div className=" flex items-center ">
                            <img src={adr} className=' md:w-4 w-2 mr-2 ' alt="" />
                            <p className="font-normal  md:text-[17px] text-[10px]  md:leading-[35px] leading-[20px] text-white font-inter" >Bd Mohamed V . Technopark . Bureau T122 . TANGER </p>
                        </div>
                        <div className=" flex items-center ">
                            <img src={ema} className=' md:w-4 w-2 mr-2 ' alt="" />
                            <p className="font-normal  md:text-[17px] text-[10px]  md:leading-[35px] leading-[20px] text-white font-inter" >contact@maroharvest.com</p>
                        </div>             
                        {/* <p className="font-normal  md:text-[17px] text-[10px]  md:leading-[35px] leading-[20px] text-white font-inter" >Österreich</p> */}
                    </div>
                    {/* <div className="">
                        <p className="font-inter font-bold md:text-[25px] text-[10px] md:leading-[30px] leading-[20px] text-white" >Frutura Obst & Gemüse<br />Kompetenzzentrum GmbH</p>
                        <p className="font-normal md:text-[17px]  text-[10px] md:leading-[35px]  leading-[20px] text-white font-inter " >+43 (0)3334 41800</p>
                        <p className="font-normal  md:text-[17px] text-[10px]  md:leading-[35px] leading-[20px] text-white font-inter" >office@frutura.com</p>
                        <p className="font-normal  md:text-[17px] text-[10px]  md:leading-[35px] leading-[20px] text-white font-inter" >Fruturastraße 1</p>
                        <p className="font-normal  md:text-[17px] text-[10px]  md:leading-[35px] leading-[20px] text-white font-inter" >8224 Hartl</p>
                        <p className="font-normal  md:text-[17px] text-[10px]  md:leading-[35px] leading-[20px] text-white font-inter" >Österreich</p>
                    </div> */}
                </div>
                <div className="">
                    <div className=" w-fit absolute bottom-5 right-5 z-30 flex  ">
                        <a href="https://www.facebook.com/profile.php?id=100092666692071&mibextid=ZbWKwL"><img src={face} className=' w-[30px]  cursor-pointer ml-2 ' alt="" /></a>
                        <a href="https://www.instagram.com/maroharvest/?utm_source=ig_web_button_share_sheet"><img src={istagram} className=' w-[30px] cursor-pointer ml-2' alt="" /></a>
                        <a href="https://www.linkedin.com/company/chakkor-trading-company/"><img src={linkden} className=' w-[30px] cursor-pointer ml-2' alt="" /></a>
                        <a href="https://wa.me/+212662385218" ><img src={wahtasap} className=' w-[30px] cursor-pointer ml-2 ' alt="" /></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;