import React, { useRef, useEffect } from 'react';
import zone from '../../img/map2.png';
import one from '../../img/aboutus/one.jpg';
import two from '../../img/aboutus/two.jpg';
import three from '../../img/aboutus/three.png';
import { gsap } from 'gsap';

import { ScrollTrigger } from 'gsap/ScrollTrigger';

const Aboutus = () => {

    const about = useRef(null); // Ref for the first title
    const images = useRef(null); // Ref for the second title
    const textzone = useRef(null); // Ref for the second title


    gsap.registerPlugin(ScrollTrigger); // Register the ScrollTrigger plugin

    useEffect(() => {
        // const tl = gsap.timeline();
        const titelElement = about.current;
        const textzon = textzone.current;

        const image = images.current;

        // First animation for the first title (no scroll trigger, animate immediately)
        // gsap.fromTo(
        //     titelElement,
        //     { opacity: 0, y: 100 },
        //     { opacity: 1, y: 0, duration: 1 }
        // );

        // Second animation for the second title, triggered by scrolling
        gsap.fromTo(
            titelElement,
            { opacity: 0, y: 100 }, // Initial state
            {
                opacity: 1,
                y: 0,
                duration: 1,
                scrollTrigger: {
                    trigger: titelElement, // Element to trigger the animation
                    start: "top bottom",    // Start when the top of the element hits the center of the viewport
                    toggleActions: "play none restart none" // Play the animation when triggered
                }
            }
        );

        gsap.fromTo(
            textzon,
            { opacity: 0, y: 100 }, // Initial state
            {
                opacity: 1,
                y: 0,
                duration: 1,
                scrollTrigger: {
                    trigger: textzon, // Element to trigger the animation
                    start: "top bottom",    // Start when the top of the element hits the center of the viewport
                    toggleActions: "play none restart none" // Play the animation when triggered
                }
            }
        );
        gsap.fromTo(
            image,
            { opacity: 0, y: 100 }, // Initial state
            {
                opacity: 1,
                y: 0,
                duration: 1,
                scrollTrigger: {
                    trigger: image, // Element to trigger the animation
                    start: "top bottom",    // Start when the top of the element hits the center of the viewport
                    toggleActions: "play none restart none" // Play the animation when triggered
                }
            }
        );

    }, []);

    return (
        <div className=" h-fit w-full md:mt-10 mt-2" id="Apropo" >
            {/* <div className="m-0 flex flex-col items-start my-24 ">
                <p className="md:text-[60px] text-[40px] font-bold md:leading-[73px] leading-[63px] text-start text-black font-inter  " >A propos de nous</p>
                <div className="md:mt-2 mt-1 md:w-[400px] w-[350px] h-0 md:border-[5px] border-[3px] border-[#F98B2A]"></div>
            </div> */}
            <div ref={about}>
                <p className='text-center text-[25px] md:text-[50px] font-Poppins font-bold px-3 md:px-0  md:leading-[39px] text-[#218555] max-w-[1280px] mx-auto md:my-16 my-5 uppercase fadeIn  block '  >A propos de nous</p>
                <p className=" text-center text-[15px] md:text-[20px] font-Poppins font-normal px-3 md:px-0  md:leading-[29px] text-[#e09857] max-w-[1280px] mx-auto " >Maroharvest, une marque de Chakkor Trading Company, <br />spécialisée dans l'exportation de fruits et légumes frais, soigneusement sélectionnés pour leur qualité et leur fraîcheur. Nous proposons une large gamme de produits agricoles, notamment des agrumes, légumes et herbes aromatiques cultivés dans les meilleures conditions au Maroc.
                    Grâce à notre expertise et à un réseau de distribution bien établi, nous répondons aux besoins des marchés internationaux, en particulier en Afrique, avec des produits respectant les normes de qualité mondiales.
                    Notre mission est d'assurer à nos clients des produits qui se distinguent par leur goût, leur fraîcheur et leur qualité nutritionnelle, en établissant des relations solides avec nos partenaires à travers le monde, tout en respectant des pratiques agricoles durables.
                </p>
            </div>


            <div ref={images} >
                <div className=" w-[70%] flex justify-between mx-auto items-center mt-24 ">
                    <div className="md:visible w-[400px] h-[300px] flex justify-center items-center ">
                        <img src={one} className="h-full shadow-2xl" alt="" />
                    </div>
                    <div className="md:flex hidden  w-[400px] h-[300px]  justify-center items-center ">
                        <img src={two} className="h-full shadow-2xl" alt="" />
                    </div>
                    <div className="md:flex hidden  w-[400px] h-[300px]  justify-center items-center">
                        <img src={three} className=" h-full shadow-2xl" alt="" />
                    </div>
                </div>
            </div>


            <div ref={textzone}>
                <div className=" md:px-0 px-3 mt-8 md:mt-[123px] w-full flex flex-col md:flex-row  justify-between items-center max-w-[1280px] mx-auto "  >
                    <div className="max-w-[612px] max-h-[220px]  ">
                        <p className="md:text-[40px] text-[30px] font-bold text-start text-black font-inter" >Du Maroc au monde : Nos zones de distribution</p>
                        <p className="md:text-[20px] font-normal text-[14px] leading-[17px] mb-5 mt-5 md:mt-[25px] md:mb-0 md:leading-[24px] text-black font-inter" >Maroharvest s'engage à exporter ses produits frais vers plusieurs marchés internationaux. Nous livrons principalement en Afrique, mais également en Europe, notamment en France, en Espagne, et en Angleterre, ainsi qu'aux pays du Golfe</p>
                    </div>
                    <div className="w-full md:w-[612px] h-fit bg-black shadow-2xl "  >
                        <img src={zone} className=' w-full ' alt="" />
                    </div>
                </div>
            </div>



            {/* <div className="max-w-[1280px] mx-auto mt-[83px] flex flex-col ">
                <div className="">
                    <p className=" text-[60px] font-bold leading-[73px] text-start text-black font-inter" >Our History</p>
                    <div className="mt-2 w-[400px] h-0 ] border-[5px] border-[#F98B2A]"></div>
                </div>
                <div className="max-w-[1317px] max-h-[425px] mt-[39px] flex mb-5 ">
                    <div className=" bg-black w-[658px] h-[425px]" style={{ backgroundImage: `url(${infoimg})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                    <div className="w-[658px] h-[425px] flex flex-col ">
                        <div className="m-auto ml-5 ">
                            <p className="text-[40px] font-bold leading-[48px] text-[#2BB673] font-inter" >1900</p>
                            <p className="text-[40px] font-bold leading-[48px] text-[#F98B2A] font-inter" >The Idea</p>
                            <p className="w-[612px] h-[69px] text-[20px] font-normal leading-[24px] text-[#000000] font-inter" >Founding of the company “Dörrobstland Vertriebs GesmbH”, still the tree’s strong trunk today.</p>
                            <div className=" cursor-pointer ">
                                <p className="text-[15px] font-bold leading-[18px] text-[#000000] font-inter" >Discover The full story</p>
                                <div className="w-[179px] h-0 left-[796px] border-[3px] border-black"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default Aboutus;