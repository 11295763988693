// import { Link } from "react-router-dom";
import Footer from "../../Section/Footer";
import Navbar from "../../Section/Navbar";
import back from '../../img/dashbord/image00004.jpeg';
// import leg1 from '../../img/leg1.jpg';
// import leg2 from '../../img/leg2.jpg';
// import leg3 from '../../img/leg3.jpg';
import { useEffect } from 'react';
import ContactService from "../../Section/ContactService";


const Compostage = () => { 

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className=" flex flex-col ">
            <Navbar />
            <div className="z-10  ">
                <div className="w-full h-[550px] relative flex items-center justify-center flex-col p-5 " style={{ backgroundImage: `url(${back})`, backgroundSize: 'cover', backgroundPosition: 'center' }} >
                    <div className="w-full h-full absolute top-0 z-10 bg-[#F98B2A] opacity-20"></div>
                    <h1 className='font-inter font-bold md:text-[58px] text-[60px] md:leading-[91px] leading-[73px] text-white z-20 ' >Compostage et gestion des déchets organiques</h1>
                    {/* <div className="h-auto md:w-[90%] w-[0px] border-t-[10px] border-[#F98B2A]"></div> */}
                </div>
                <p className="mt-5 md:mt-10 text-center text-[15px] md:text-[31.9202px] font-Inter font-bold px-3 md:px-0  md:leading-[39px] text-[#F98B2A] max-w-[1280px] mx-auto " >Le compostage est une solution naturelle et efficace pour la gestion des déchets agricoles et urbains, transformant les résidus organiques en engrais riche en nutriments pour les sols. Cette pratique aide à réduire les déchets tout en améliorant la fertilité des terres agricoles. Chez <b className="text-[#2BB673]" >Maroharvest</b>, nous souhaitons encourager les exploitations agricoles à adopter le compostage pour enrichir leurs sols, tout en contribuant à la réduction des émissions de gaz à effet de serre liées aux décharges.
                </p>
                <div className="m-0 flex flex-col items-start max-w-[1280px] mt-5 mb-5 p-5 ">
                    <p className="md:text-[40px] md:pl-5 text-[30px] font-bold md:leading-[73px] leading-[40px] text-start text-black font-inter" >Évolution du projet :</p>
                    <div className="md:mt-2 mt-1 md:pl-5  md:w-[400px] w-[320px] h-0 md:border-[5px] border-[3px] border-[#F98B2A]"></div>
                </div>
                <p className="mt-5 mb-5 text-start text-[15px] md:text-[32px] font-Inter font-bold px-3 md:px-0  md:leading-[37px] text-[#000] max-w-[1280px] mx-auto" >Nous travaillons à établir des collaborations avec des start-ups marocaines en agritech, afin de faciliter l'accès à ces services pour les exploitations agricoles de toutes tailles. En parallèle, nous voulons sensibiliser les agriculteurs sur l'importance de l'agriculture de précision pour optimiser la productivité tout en minimisant l'impact environnemental.</p>
            </div>
            <ContactService nom={"Compostage et gestion des déchets organiques"}></ContactService>
            <Footer />
        </div>
    );
}
 
export default Compostage;