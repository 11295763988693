import Aboutus from "./Pages/Home/Aboutus";
import Dashboard from "./Pages/Home/Dashboard";
import Footer from "./Section/Footer";
// import Info from "./Pages/Home/Info";
// import Location from "./Pages/Home/Location";
import News from "./Pages/Home/News";
import Produit from "./Pages/Home/Produit";
import Projets from "./Pages/Home/Projets";
import Navbar from "./Section/Navbar";



function App() {
  return (
    <div className="flex flex-col z-30 overflow-hidden ">
      <Navbar></Navbar>
      <Dashboard/>
      <Aboutus/>
      {/* <Info/> */}
      <Produit/>
      <Projets/>
      <News/>
      {/* <Location/> */}
      <Footer/>
    </div>
  );
}

export default App;
