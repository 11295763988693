// import { Link } from "react-router-dom";
import Footer from "../../Section/Footer";
import Navbar from "../../Section/Navbar";
import back from '../../img/dashbord/image00003.jpeg';
// import leg1 from '../../img/leg1.jpg';
// import leg2 from '../../img/leg2.jpg';
// import leg3 from '../../img/leg3.jpg';
import { useEffect} from 'react';
import ContactService from "../../Section/ContactService";

const Hydroponie = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className=" flex flex-col ">
            <Navbar />
            <div className="z-10  ">
                <div className="w-full h-[550px] relative flex items-center justify-center flex-col  " style={{ backgroundImage: `url(${back})`, backgroundSize: 'cover', backgroundPosition: 'center' }} >
                    <div className="w-full h-full absolute top-0 z-10 bg-[#F98B2A] opacity-20"></div>
                    <h1 className='font-inter font-bold text-[60px] leading-[73px] text-white z-20 ' >Hydroponie</h1>
                    {/* <div className="h-auto w-[400px] border-t-[10px] border-[#F98B2A]"></div> */}
                </div>
                <p className=" mt-5 md:mt-10 text-center text-[15px] md:text-[31.9202px] font-Inter font-bold px-3 md:px-0  md:leading-[39px] text-[#F98B2A] max-w-[1280px] mx-auto mb-[4.5rem] " >L’hydroponie est une méthode d’agriculture hors sol qui permet de cultiver des plantes dans un environnement contrôlé, en utilisant des solutions nutritives à base d’eau au lieu de la terre. Ce procédé est de plus en plus utilisé au Maroc pour sa capacité à produire des rendements élevés tout en économisant de l'eau, une ressource précieuse dans notre région. Chez <b className="text-[#2BB673]" >Maroharvest</b>  , nous nous engageons à promouvoir l’hydroponie pour favoriser une agriculture plus efficiente et durable.
                </p>
                <div className="m-0 flex flex-col items-start max-w-[1280px]  p-5 mt-[4.5rem] mb-[4.5rem] ">
                    <p className="md:text-[40px] md:pl-5 text-[30px] font-bold md:leading-[73px] leading-[40px] text-start text-black font-inter" >Évolution du projet :</p>
                    <div className="md:mt-2 mt-1 md:pl-5  md:w-[400px] w-[320px] h-0 md:border-[5px] border-[3px] border-[#F98B2A]"></div>
                </div>
                <p className="mt-5 mb-5 text-start text-[15px] md:text-[32px] font-Inter font-bold px-3 md:px-0  md:leading-[37px] text-[#000] max-w-[1280px] mx-auto">Nous cherchons à collaborer avec des coopératives et des collectivités locales pour instaurer des programmes de compostage à grande échelle, tout en formant les agriculteurs sur les techniques de compostage efficaces. Nous envisageons également de promouvoir l'utilisation du compost dans les projets d'agriculture urbaine et hydroponique pour favoriser des systèmes agricoles circulaires et plus écologiques.</p>
            </div>
           <ContactService nom={"Hydroponie”"}></ContactService>
            <Footer />
        </div>
    );
}

export default Hydroponie;