import { Link } from 'react-router-dom';
import Logo from '../img/logoo.png';
import { useState } from 'react';
import React, { useRef, useEffect } from 'react';


import { gsap } from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';

const Navbar = () => {
    const [isActive, setActive] = useState(false);
    const rect = useRef(null);
    const logo = useRef(null);
    const menuu = useRef(null);
    const menuuS = useRef(null);

    const handleClickOnNav = () => {
        setActive(!isActive);

    };

    const handleNavLinkClick = () => {
        setActive(false);
    };

    useEffect(() => {
        const til = gsap.timeline();
        const rec = rect.current;
        const lg = logo.current;
        const men = menuu.current;
        const mens = menuuS.current;

        til.fromTo(
            rec,
            { opacity: 0, y: -100 },
            {
                opacity: 1, y: 0, duration: 1,
            }
        );
        til.fromTo(
            lg,
            { opacity: 0},
            {
                opacity: 1, duration: 1,
            }
        );
        til.fromTo(
            mens,
            { opacity: 0},
            {
                opacity: 1, duration: 1
            }
        );
        til.fromTo(
            men,
            { opacity: 0},
            {
                opacity: 1, duration: 1
            }
        );


    }, []);

    return (
        <div ref={rect} className="w-[100%]  left-0 fixed top-0 h-[68px] drop-shadow-lg bg-white z-50  ">
            <div className=" flex justify-between items-center mx-5 z-50 ">
                <Link to={`/`} className='cursor-pointer z-50 ' >
                    <img ref={logo} src={Logo} className='w-[70px] cursor-pointer ' alt="" />
                </Link>
                <label ref={menuuS} className="hamburger md:hidden flex  cursor-pointer z-50 ">
                    <input type="checkbox"
                        checked={isActive}
                        onChange={handleClickOnNav} />
                    <svg viewBox="0 0 32 32">
                        <path
                            d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"
                            className="line line-top-bottom"
                        ></path>
                        <path d="M7 16 27 16" class="line"></path>
                    </svg>
                </label>
                <div className={`md:hidden w-full h-[80vh] bg-white flex flex-col justify-between py-10 items-center absolute top-[60px] z-40 left-0  transition-all duration-700 ease-in-out transform ${isActive ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'} `}>
                    <a className={`font-Inter p-5 border-b-2 border-[#F98B2A] w-[90%] text-center transition-all delay-300 duration-700 ease-in-out transform ${isActive ? 'w-[90%]' : 'w-[50%]'} `} href="/#Apropo" onClick={handleNavLinkClick} >A propos de nous</a>
                    <a className={`font-Inter p-5 border-b-2 border-[#F98B2A] w-[90%] text-center transition-all delay-300 duration-700 ease-in-out transform ${isActive ? 'w-[90%]' : 'w-[50%]'} `} href="/#Pr" onClick={handleNavLinkClick} >Nos produits</a>
                    <a className={`font-Inter p-5 border-b-2 border-[#F98B2A] w-[90%] text-center transition-all delay-300 duration-700 ease-in-out transform ${isActive ? 'w-[90%]' : 'w-[50%]'} `} href="/#Ser" onClick={handleNavLinkClick} >Nos Projets</a>
                    {/* <p className={`font-Inter p-5 border-b-2 border-[#F98B2A] w-[90%] text-center transition-all delay-300 duration-700 ease-in-out transform ${isActive ? 'w-[90%]' : 'w-[50%]'} `} >Our History</p> */}

                    <Link to={`/contact`} className='font-Inter  bg-[#F98B2A] rounded-[30px] p-3 px-8 text-white cursor-pointer ' >
                        <p>Nous contactez</p>
                    </Link>
                </div>
                <div className={`w-full h-[100vh] bg-black opacity-0 absolute top-[70px] left-0 z-30 ${isActive ? 'visible' : 'hidden'} `} onClick={handleNavLinkClick}></div>
                <div ref={menuu} className="hidden  md:flex items-center  ">
                    <a className=" font-Inter pr-[1.5rem] cursor-pointer " href="/#Apropo" >En savoir plus</a>
                    <a className="font-Inter pr-[1.5rem] " href='/#Pr' >Nos Produits</a>
                    <a className="font-Inter pr-[1.5rem] " href='/#Ser' >Nos Projets</a>
                    {/* <p className="font-Inter pr-2 " >Our History</p> */}
                    <Link to={`/contact`} className='font-Inter  bg-[#F98B2A] rounded-[30px] p-3 px-8 text-white cursor-pointer ' >
                        <p>Nous contactez</p>
                    </Link>

                </div>
            </div>
        </div>
    );
}

export default Navbar;