import { useEffect ,useRef } from "react";
import Footer from "../../../Section/Footer";
import Navbar from "../../../Section/Navbar";
import leg1 from '../../../img/leg/car.jpg';
import leg2 from '../../../img/leg/tommate.jpg';
import leg3 from '../../../img/leg/PoivronJaune.jpg';
import leg4 from '../../../img/leg/Poivronrouge2.jpg';
import leg5 from '../../../img/leg/Poivronvert.jpg';
import leg6 from '../../../img/leg/Poivronvertlong.jpg';
import leg7 from '../../../img/leg/Pimentvert.jpg';
import leg8 from '../../../img/leg/Pimentrouge.jpg';
import leg9 from '../../../img/leg/Tomatecerise.jpg';
import leg10 from '../../../img/leg/Concombre.jpg';
import leg11 from '../../../img/leg/ChouCabus.jpg';
import leg12 from '../../../img/leg/Pommedeterre.jpg';
import leg13 from '../../../img/leg/Oignon.jpg';
import leg14 from '../../../img/leg/Haricotvert.jpg';
import leg15 from '../../../img/leg/Haricotplat.jpg';
import { Link } from "react-router-dom";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import { useGSAP } from '@gsap/react';
import { gsap } from 'gsap';

const legumes = [
    {
        Id: '1',
        image: leg1,
        nom: "Carottes",
        validity: [
            {
                mounth: 'Janvier',
                valid: true,
            },
            {
                mounth: 'Février',
                valid: true,
            },
            {
                mounth: 'Mars',
                valid: true,
            },
            {
                mounth: 'Avril',
                valid: true,
            },
            {
                mounth: 'Mai',
                valid: true,
            },
            {
                mounth: 'Juin',
                valid: true,
            },
            {
                mounth: 'Juillet',
                valid: true,
            },
            {
                mounth: 'Août',
                valid: true,
            },
            {
                mounth: 'Septembre',
                valid: true,
            },
            {
                mounth: 'Octobre',
                valid: true,
            },
            {
                mounth: 'Novembre',
                valid: true,
            },
            {
                mounth: 'Décembre',
                valid: true,
            },
        ]
    },
    {
        Id: '2',
        image: leg2,
        nom: "Tomates",
        validity: [
            {
                mounth: 'Janvier',
                valid: true,
            },
            {
                mounth: 'Février',
                valid: true,
            },
            {
                mounth: 'Mars',
                valid: true,
            },
            {
                mounth: 'Avril',
                valid: true,
            },
            {
                mounth: 'Mai',
                valid: true,
            },
            {
                mounth: 'Juin',
                valid: true,
            },
            {
                mounth: 'Juillet',
                valid: true,
            },
            {
                mounth: 'Août',
                valid: true,
            },
            {
                mounth: 'Septembre',
                valid: true,
            },
            {
                mounth: 'Octobre',
                valid: true,
            },
            {
                mounth: 'Novembre',
                valid: true,
            },
            {
                mounth: 'Décembre',
                valid: true,
            },
        ]
    },
    {
        Id: '3',
        image: leg3,
        nom: "Poivron jaune California Wonder",
        validity: [
            {
                mounth: 'Janvier',
                valid: true,
            },
            {
                mounth: 'Février',
                valid: true,
            },
            {
                mounth: 'Mars',
                valid: true,
            },
            {
                mounth: 'Avril',
                valid: true,
            },
            {
                mounth: 'Mai',
                valid: true,
            },
            {
                mounth: 'Juin',
                valid: true,
            },
            {
                mounth: 'Juillet',
                valid: false,
            },
            {
                mounth: 'Août',
                valid: false,
            },
            {
                mounth: 'Septembre',
                valid: false,
            },
            {
                mounth: 'Octobre',
                valid: false,
            },
            {
                mounth: 'Novembre',
                valid: false,
            },
            {
                mounth: 'Décembre',
                valid: true,
            },
        ]
    },
    {
        Id: '4',
        image: leg4,
        nom: "Poivron rouge California Wonder",
        validity: [
            {
                mounth: 'Janvier',
                valid: true,
            },
            {
                mounth: 'Février',
                valid: true,
            },
            {
                mounth: 'Mars',
                valid: true,
            },
            {
                mounth: 'Avril',
                valid: true,
            },
            {
                mounth: 'Mai',
                valid: true,
            },
            {
                mounth: 'Juin',
                valid: true,
            },
            {
                mounth: 'Juillet',
                valid: false,
            },
            {
                mounth: 'Août',
                valid: false,
            },
            {
                mounth: 'Septembre',
                valid: false,
            },
            {
                mounth: 'Octobre',
                valid: false,
            },
            {
                mounth: 'Novembre',
                valid: false,
            },
            {
                mounth: 'Décembre',
                valid: true,
            },
        ]
    },
    {
        Id: '5',
        image: leg5,
        nom: "Poivron vert California Wonder ",
        validity: [
            {
                mounth: 'Janvier',
                valid: true,
            },
            {
                mounth: 'Février',
                valid: true,
            },
            {
                mounth: 'Mars',
                valid: true,
            },
            {
                mounth: 'Avril',
                valid: true,
            },
            {
                mounth: 'Mai',
                valid: true,
            },
            {
                mounth: 'Juin',
                valid: true,
            },
            {
                mounth: 'Juillet',
                valid: true,
            },
            {
                mounth: 'Août',
                valid: true,
            },
            {
                mounth: 'Septembre',
                valid: true,
            },
            {
                mounth: 'Octobre',
                valid: true,
            },
            {
                mounth: 'Novembre',
                valid: true,
            },
            {
                mounth: 'Décembre',
                valid: true,
            },
        ]
    },
    {
        Id: '6',
        image: leg6,
        nom: "Poivron vert long vert ",
        validity: [
            {
                mounth: 'Janvier',
                valid: true,
            },
            {
                mounth: 'Février',
                valid: true,
            },
            {
                mounth: 'Mars',
                valid: true,
            },
            {
                mounth: 'Avril',
                valid: true,
            },
            {
                mounth: 'Mai',
                valid: true,
            },
            {
                mounth: 'Juin',
                valid: true,
            },
            {
                mounth: 'Juillet',
                valid: false,
            },
            {
                mounth: 'Août',
                valid: false,
            },
            {
                mounth: 'Septembre',
                valid: false,
            },
            {
                mounth: 'Octobre',
                valid: false,
            },
            {
                mounth: 'Novembre',
                valid: false,
            },
            {
                mounth: 'Décembre',
                valid: true,
            },
        ]
    },
    {
        Id: '7',
        image: leg7,
        nom: "Piment vert",
        validity: [
            {
                mounth: 'Janvier',
                valid: true,
            },
            {
                mounth: 'Février',
                valid: true,
            },
            {
                mounth: 'Mars',
                valid: true,
            },
            {
                mounth: 'Avril',
                valid: true,
            },
            {
                mounth: 'Mai',
                valid: true,
            },
            {
                mounth: 'Juin',
                valid: true,
            },
            {
                mounth: 'Juillet',
                valid: true,
            },
            {
                mounth: 'Août',
                valid: true,
            },
            {
                mounth: 'Septembre',
                valid: true,
            },
            {
                mounth: 'Octobre',
                valid: true,
            },
            {
                mounth: 'Novembre',
                valid: true,
            },
            {
                mounth: 'Décembre',
                valid: true,
            },
        ]
    },
    {
        Id: '8',
        image: leg8,
        nom: "Piment rouge",
        validity: [
            {
                mounth: 'Janvier',
                valid: true,
            },
            {
                mounth: 'Février',
                valid: true,
            },
            {
                mounth: 'Mars',
                valid: true,
            },
            {
                mounth: 'Avril',
                valid: true,
            },
            {
                mounth: 'Mai',
                valid: true,
            },
            {
                mounth: 'Juin',
                valid: true,
            },
            {
                mounth: 'Juillet',
                valid: true,
            },
            {
                mounth: 'Août',
                valid: true,
            },
            {
                mounth: 'Septembre',
                valid: true,
            },
            {
                mounth: 'Octobre',
                valid: true,
            },
            {
                mounth: 'Novembre',
                valid: true,
            },
            {
                mounth: 'Décembre',
                valid: true,
            },
        ]
    },
    {
        Id: '9',
        image: leg9,
        nom: "Tomate cerise",
        validity: [
            {
                mounth: 'Janvier',
                valid: true,
            },
            {
                mounth: 'Février',
                valid: true,
            },
            {
                mounth: 'Mars',
                valid: true,
            },
            {
                mounth: 'Avril',
                valid: true,
            },
            {
                mounth: 'Mai',
                valid: true,
            },
            {
                mounth: 'Juin',
                valid: true,
            },
            {
                mounth: 'Juillet',
                valid: true,
            },
            {
                mounth: 'Août',
                valid: true,
            },
            {
                mounth: 'Septembre',
                valid: true,
            },
            {
                mounth: 'Octobre',
                valid: true,
            },
            {
                mounth: 'Novembre',
                valid: true,
            },
            {
                mounth: 'Décembre',
                valid: true,
            },
        ]
    },
    {
        Id: '10',
        image: leg10,
        nom: "Concombre",
        validity: [
            {
                mounth: 'Janvier',
                valid: false,
            },
            {
                mounth: 'Février',
                valid: false,
            },
            {
                mounth: 'Mars',
                valid: true,
            },
            {
                mounth: 'Avril',
                valid: true,
            },
            {
                mounth: 'Mai',
                valid: true,
            },
            {
                mounth: 'Juin',
                valid: true,
            },
            {
                mounth: 'Juillet',
                valid: true,
            },
            {
                mounth: 'Août',
                valid: true,
            },
            {
                mounth: 'Septembre',
                valid: true,
            },
            {
                mounth: 'Octobre',
                valid: true,
            },
            {
                mounth: 'Novembre',
                valid: true,
            },
            {
                mounth: 'Décembre',
                valid: false,
            },
        ]
    },
    {
        Id: '11',
        image: leg11,
        nom: "Chou Cabus",
        validity: [
            {
                mounth: 'Janvier',
                valid: true,
            },
            {
                mounth: 'Février',
                valid: true,
            },
            {
                mounth: 'Mars',
                valid: true,
            },
            {
                mounth: 'Avril',
                valid: true,
            },
            {
                mounth: 'Mai',
                valid: false,
            },
            {
                mounth: 'Juin',
                valid: false,
            },
            {
                mounth: 'Juillet',
                valid: false,
            },
            {
                mounth: 'Août',
                valid: false,
            },
            {
                mounth: 'Septembre',
                valid: false,
            },
            {
                mounth: 'Octobre',
                valid: false,
            },
            {
                mounth: 'Novembre',
                valid: true,
            },
            {
                mounth: 'Décembre',
                valid: true,
            },
        ]
    },
    {
        Id: '12',
        image: leg12,
        nom: "Pomme de terre",
        validity: [
            {
                mounth: 'Janvier',
                valid: true,
            },
            {
                mounth: 'Février',
                valid: true,
            },
            {
                mounth: 'Mars',
                valid: true,
            },
            {
                mounth: 'Avril',
                valid: true,
            },
            {
                mounth: 'Mai',
                valid: true,
            },
            {
                mounth: 'Juin',
                valid: true,
            },
            {
                mounth: 'Juillet',
                valid: true,
            },
            {
                mounth: 'Août',
                valid: true,
            },
            {
                mounth: 'Septembre',
                valid: false,
            },
            {
                mounth: 'Octobre',
                valid: true,
            },
            {
                mounth: 'Novembre',
                valid: true,
            },
            {
                mounth: 'Décembre',
                valid: true,
            },
        ]
    },
    {
        Id: '13',
        image: leg13,
        nom: "Oignon ",
        validity: [
            {
                mounth: 'Janvier',
                valid: true,
            },
            {
                mounth: 'Février',
                valid: false,
            },
            {
                mounth: 'Mars',
                valid: false,
            },
            {
                mounth: 'Avril',
                valid: false,
            },
            {
                mounth: 'Mai',
                valid: true,
            },
            {
                mounth: 'Juin',
                valid: true,
            },
            {
                mounth: 'Juillet',
                valid: true,
            },
            {
                mounth: 'Août',
                valid: true,
            },
            {
                mounth: 'Septembre',
                valid: true,
            },
            {
                mounth: 'Octobre',
                valid: false,
            },
            {
                mounth: 'Novembre',
                valid: true,
            },
            {
                mounth: 'Décembre',
                valid: true,
            },
        ]
    },
    {
        Id: '14',
        image: leg14,
        nom: "Haricot vert",
        validity: [
            {
                mounth: 'Janvier',
                valid: true,
            },
            {
                mounth: 'Février',
                valid: true,
            },
            {
                mounth: 'Mars',
                valid: true,
            },
            {
                mounth: 'Avril',
                valid: true,
            },
            {
                mounth: 'Mai',
                valid: true,
            },
            {
                mounth: 'Juin',
                valid: false,
            },
            {
                mounth: 'Juillet',
                valid: false,
            },
            {
                mounth: 'Août',
                valid: false,
            },
            {
                mounth: 'Septembre',
                valid: false,
            },
            {
                mounth: 'Octobre',
                valid: true,
            },
            {
                mounth: 'Novembre',
                valid: true,
            },
            {
                mounth: 'Décembre',
                valid: true,
            },
        ]
    },
    {
        Id: '15',
        image: leg15,
        nom: "Haricot plat",
        validity: [
            {
                mounth: 'Janvier',
                valid: true,
            },
            {
                mounth: 'Février',
                valid: true,
            },
            {
                mounth: 'Mars',
                valid: true,
            },
            {
                mounth: 'Avril',
                valid: true,
            },
            {
                mounth: 'Mai',
                valid: true,
            },
            {
                mounth: 'Juin',
                valid: false,
            },
            {
                mounth: 'Juillet',
                valid: false,
            },
            {
                mounth: 'Août',
                valid: false,
            },
            {
                mounth: 'Septembre',
                valid: false,
            },
            {
                mounth: 'Octobre',
                valid: true,
            },
            {
                mounth: 'Novembre',
                valid: true,
            },
            {
                mounth: 'Décembre',
                valid: true,
            },
        ]
    },
]

const Legume = () => {
    const Legumes = useRef(null);

    gsap.registerPlugin(ScrollTrigger);

    useEffect(()=>{
        const tel = gsap.timeline(); 
        const legume = Legumes.current;
        tel.fromTo(
            ".box",
            { opacity: 0, y: 100 },
            {
                opacity: 1, y: 0, duration: 0.5,
                stagger: 0.5,
            }

        );
        gsap.fromTo(
            legume,
            {opacity : 0 ,y:-140 },
            {opacity :1 , y:0 , duration:1 }
        )
       
    })

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="">
            <Navbar className="fixed top-0 w-full z-50 " />
            <p ref={Legumes} className='font-inter font-bold text-[40px] text-center leading-[73px] text-[#F98B2A] z-20 mt-28 uppercase   ' >légumes</p>
            <div className=" w-[90%] mx-auto  h-fit mt-14 flex flex-wrap justify-center gap-x-4 gap-y-10 ">
                {legumes.map((legume) => {
                    return (
                        <Link className="md:w-1/4 w-full box " to={'/pr-info'}
                            state={
                                {
                                    id: legume.Id,
                                    image: legume.image,
                                    nom: legume.nom,
                                    valid: legume.validity,
                                    cat:"Légumes"
                                }
                            }
                        >
                            <div key={legume.Id} className=" h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                                <div className=" bg-white w-full n h-[308.03px] flex justify-center items-start " >
                                    <img className=" m-auto    h-auto  w-full" src={legume.image} alt="" />
                                </div>
                                <div className="  w-full justify-center flex items-center flex-col ">
                                    <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >{legume.nom}</p>
                                </div>
                            </div>
                        </Link>
                    );
                })}
                {/* <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                    <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg1})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                    <div className=" w-full justify-center flex items-center flex-col ">
                        <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                    </div>
                </div>
                <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                    <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg2})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                    <div className=" w-full justify-center flex items-center flex-col ">
                        <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                    </div>
                </div>
                <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                    <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg3})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                    <div className=" w-full justify-center flex items-center flex-col ">
                        <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                    </div>
                </div>
                <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                    <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg3})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                    <div className=" w-full justify-center flex items-center flex-col ">
                        <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                    </div>
                </div>
                <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                    <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg1})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                    <div className=" w-full justify-center flex items-center flex-col ">
                        <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                    </div>
                </div>
                <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                    <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg2})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                    <div className=" w-full justify-center flex items-center flex-col ">
                        <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                    </div>
                </div>
                <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                    <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg3})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                    <div className=" w-full justify-center flex items-center flex-col ">
                        <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                    </div>
                </div> */}

            </div>
            <Footer />
        </div>
    );
}

export default Legume;