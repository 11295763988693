import { useEffect , useRef } from "react";
import Footer from "../../../Section/Footer";
import Navbar from "../../../Section/Navbar";
import leg1 from '../../../img/herbes/Menthe.jpg';
import leg2 from '../../../img/herbes/Persil.jpg';
import leg3 from '../../../img/herbes/Coriandre.jpg';
import leg4 from '../../../img/herbes/Aneth.jpg';
import leg5 from '../../../img/herbes/Epinard.jpg';
import leg6 from '../../../img/herbes/Armoise.jpg';
import leg7 from '../../../img/herbes/Basilic.jpg';
import { Link } from "react-router-dom";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import { useGSAP } from '@gsap/react';
import { gsap } from 'gsap';

const legumes = [
    {
        Id: '26',
        image: leg1,
        nom: "Menthe",
        validity: [
            {
             mounth:'Janvier',
             valid: true ,   
            },
            {
             mounth:'Février',
             valid:true,   
            },
            {
             mounth:'Mars',
             valid:true,   
            },
            {
             mounth:'Avril',
             valid:true,   
            },
            {
             mounth:'Mai',
             valid:false,   
            },
            {
             mounth:'Juin',
             valid:false,   
            },
            {
             mounth:'Juillet',
             valid:false,   
            },
            {
             mounth:'Août',
             valid:false,   
            },
            {
             mounth:'Septembre',
             valid:false,   
            },
            {
             mounth:'Octobre',
             valid:false,   
            },
            {
             mounth:'Novembre',
             valid:true,   
            },
            {
             mounth:'Décembre',
             valid:true,   
            },
        ]
    },
    {
        Id: '27',
        image: leg2,
        nom: "Persil",
        validity: [
            {
                mounth: 'Janvier',
                valid: true,
            },
            {
                mounth: 'Février',
                valid: true,
            },
            {
                mounth: 'Mars',
                valid: true,
            },
            {
                mounth: 'Avril',
                valid: true,
            },
            {
                mounth: 'Mai',
                valid: true,
            },
            {
                mounth: 'Juin',
                valid: false,
            },
            {
                mounth: 'Juillet',
                valid: true,
            },
            {
                mounth: 'Août',
                valid: true,
            },
            {
                mounth: 'Septembre',
                valid: true,
            },
            {
                mounth: 'Octobre',
                valid: true,
            },
            {
                mounth: 'Novembre',
                valid: true,
            },
            {
                mounth: 'Décembre',
                valid: true,
            },
        ]
    },
    {
        Id: '28',
        image: leg3,
        nom: "Coriandre",
        validity: [
            {
             mounth:'Janvier',
             valid: true ,   
            },
            {
             mounth:'Février',
             valid:true,   
            },
            {
             mounth:'Mars',
             valid:true,   
            },
            {
             mounth:'Avril',
             valid:true,   
            },
            {
             mounth:'Mai',
             valid:false,   
            },
            {
             mounth:'Juin',
             valid:false,   
            },
            {
             mounth:'Juillet',
             valid:false,   
            },
            {
             mounth:'Août',
             valid:false,   
            },
            {
             mounth:'Septembre',
             valid:false,   
            },
            {
             mounth:'Octobre',
             valid:false,   
            },
            {
             mounth:'Novembre',
             valid:true,   
            },
            {
             mounth:'Décembre',
             valid:true,   
            },
        ]
    },
    {
        Id: '29',
        image: leg4,
        nom: "Aneth",
        validity: [
            {
             mounth:'Janvier',
             valid: true ,   
            },
            {
             mounth:'Février',
             valid:true,   
            },
            {
             mounth:'Mars',
             valid:true,   
            },
            {
             mounth:'Avril',
             valid:true,   
            },
            {
             mounth:'Mai',
             valid:false,   
            },
            {
             mounth:'Juin',
             valid:false,   
            },
            {
             mounth:'Juillet',
             valid:false,   
            },
            {
             mounth:'Août',
             valid:false,   
            },
            {
             mounth:'Septembre',
             valid:false,   
            },
            {
             mounth:'Octobre',
             valid:false,   
            },
            {
             mounth:'Novembre',
             valid:true,   
            },
            {
             mounth:'Décembre',
             valid:true,   
            },
        ]
    },
    {
        Id: '30',
        image: leg5,
        nom: "Epinard",
        validity: [
            {
             mounth:'Janvier',
             valid: true ,   
            },
            {
             mounth:'Février',
             valid:true,   
            },
            {
             mounth:'Mars',
             valid:true,   
            },
            {
             mounth:'Avril',
             valid:true,   
            },
            {
             mounth:'Mai',
             valid:false,   
            },
            {
             mounth:'Juin',
             valid:false,   
            },
            {
             mounth:'Juillet',
             valid:false,   
            },
            {
             mounth:'Août',
             valid:false,   
            },
            {
             mounth:'Septembre',
             valid:false,   
            },
            {
             mounth:'Octobre',
             valid:false,   
            },
            {
             mounth:'Novembre',
             valid:true,   
            },
            {
             mounth:'Décembre',
             valid:true,   
            },
        ]
    },
    {
        Id: '31',
        image: leg6,
        nom: "Armoise",
        validity: [
            {
             mounth:'Janvier',
             valid: true ,   
            },
            {
             mounth:'Février',
             valid:true,   
            },
            {
             mounth:'Mars',
             valid:true,   
            },
            {
             mounth:'Avril',
             valid:true,   
            },
            {
             mounth:'Mai',
             valid:false,   
            },
            {
             mounth:'Juin',
             valid:false,   
            },
            {
             mounth:'Juillet',
             valid:false,   
            },
            {
             mounth:'Août',
             valid:false,   
            },
            {
             mounth:'Septembre',
             valid:false,   
            },
            {
             mounth:'Octobre',
             valid:false,   
            },
            {
             mounth:'Novembre',
             valid:true,   
            },
            {
             mounth:'Décembre',
             valid:true,   
            },
        ]
    },
    {
        Id: '32',
        image: leg7,
        nom: "Basilic",
        validity: [
            {
                mounth: 'Janvier',
                valid: false,
            },
            {
                mounth: 'Février',
                valid: false,
            },
            {
                mounth: 'Mars',
                valid: true,
            },
            {
                mounth: 'Avril',
                valid: true,
            },
            {
                mounth: 'Mai',
                valid: true,
            },
            {
                mounth: 'Juin',
                valid: true,
            },
            {
                mounth: 'Juillet',
                valid: true,
            },
            {
                mounth: 'Août',
                valid: true,
            },
            {
                mounth: 'Septembre',
                valid: true,
            },
            {
                mounth: 'Octobre',
                valid: true,
            },
            {
                mounth: 'Novembre',
                valid: true,
            },
            {
                mounth: 'Décembre',
                valid: false,
            },
        ]
    }
]


const Legume = () => {
    const herbs = useRef(null);
    gsap.registerPlugin(ScrollTrigger);

    useEffect(()=>{
        const tel = gsap.timeline(); 
        const herb = herbs.current;
        tel.fromTo(
            ".box",
            { opacity: 0, y: 100 },
            {
                opacity: 1, y: 0, duration: 0.5,
                stagger: 0.5,
            }

        );
        gsap.fromTo(
            herb,
            {opacity : 0 ,y:-140 },
            {opacity :1 , y:0 , duration:1 }
        )
       
    })

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="">
            <Navbar className="fixed top-0 w-full z-50 " />
            <p ref={herbs} className='font-inter font-bold text-[40px] text-center leading-[73px] text-[#2BB673] z-20 mt-28 uppercase' >Herbes aromatiques fraîches</p>
            <div className=" w-[90%] mx-auto h-fit mt-14 flex flex-wrap justify-center gap-x-4 gap-y-10 ">
                {legumes.map((legume) => {
                    return (
                        <Link className=" box md:w-1/4 w-full " to={'/pr-info'}
                            state={
                                {
                                    id: legume.Id,
                                    image: legume.image,
                                    nom: legume.nom,
                                    valid: legume.validity,
                                    cat:"Herbes"
                                }
                            }
                        >
                            <div key={legume.Id} className=" h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                                <div className=" bg-white w-full n h-[308.03px] flex justify-center items-start " >
                                    <img className=" m-auto    h-auto  w-full" src={legume.image} alt="" />
                                </div>
                                <div className="  w-full justify-center flex items-center flex-col ">
                                    <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >{legume.nom}</p>
                                </div>
                            </div>
                        </Link>
                    );
                })}
                {/* <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                    <div className=" bg-black w-full h-[308.03px] slideUp " style={{ backgroundImage: `url(${leg1})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                    <div className=" w-full justify-center flex items-center flex-col ">
                        <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 slideUp " >Name product</p>
                    </div>
                </div>
                <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                    <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg2})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                    <div className=" w-full justify-center flex items-center flex-col ">
                        <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                    </div>
                </div>
                <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                    <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg3})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                    <div className=" w-full justify-center flex items-center flex-col ">
                        <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                    </div>
                </div>
                <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                    <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg3})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                    <div className=" w-full justify-center flex items-center flex-col ">
                        <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                    </div>
                </div>
                <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                    <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg1})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                    <div className=" w-full justify-center flex items-center flex-col ">
                        <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                    </div>
                </div>
                <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                    <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg2})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                    <div className=" w-full justify-center flex items-center flex-col ">
                        <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                    </div>
                </div>
                <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                    <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg3})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                    <div className=" w-full justify-center flex items-center flex-col ">
                        <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                    </div>
                </div> */}

            </div>
            <Footer />
        </div>
    );
}

export default Legume;