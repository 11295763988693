import { Link } from 'react-router-dom';
import back from '../../img/News2.jpg';

// import leg4 from '../../img/leg4.jpg';
// import leg5 from '../../img/leg5.jpg';
// import leg6 from '../../img/leg6.jpg';
// import leg7 from '../../img/leg7.jpg';
// import leg8 from '../../img/leg8.jpg';
import Footer from '../../Section/Footer';
import { useEffect } from 'react';
import Navbar from '../../Section/Navbar';

import leg1 from '../../img/leg/car.jpg';
import leg2 from '../../img/leg/tommate.jpg';
import leg3 from '../../img/leg/PoivronJaune.jpg';

import leg4 from '../../img/herbes/Menthe.jpg';
import leg5 from '../../img/herbes/Persil.jpg';
import leg6 from '../../img/herbes/Coriandre.jpg';

import leg7 from '../../img/fruits/Fraise.jpg';
import leg8 from '../../img/fruits/Framboise.jpg';
import leg9 from '../../img/fruits/Prune.jpg';


const legumes = [
    {
        Id: '1',
        image: leg1,
        nom: "Carottes",
        validity: ''
    },
    {
        Id: '2',
        image: leg2,
        nom: "Tomates",
        validity: ''
    },
    {
        Id: '3',
        image: leg3,
        nom: "Poivron jaune California Wonder",
        validity: ''
    },
]

const herbes = [
    {
        Id: '26',
        image: leg4,
        nom: "Menthe",
        validity: ''
    },
    {
        Id: '27',
        image: leg5,
        nom: "Persil",
        validity: ''
    },
    {
        Id: '28',
        image: leg6,
        nom: "Coriandre",
        validity: ''
    },
]

const fruits = [
    {
        Id: '16',
        image: leg7,
        nom: "Fraise",
        validity: ''
    },
    {
        Id: '17',
        image: leg8,
        nom: "Framboise",
        validity: ''
    },
    {
        Id: '18',
        image: leg9,
        nom: "Prune",
        validity: ''
    }
]

const AllProducts = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className=" relative flex flex-col ">
            <Navbar className="fixed top-0 w-full z-50 " />
            <div className="z-10  ">
                <div className="w-full h-[550px] relative flex items-center justify-center flex-col  " style={{ backgroundImage: `url(${back})`, backgroundSize: 'cover', backgroundPosition: 'center' }} >
                    <div className="w-full h-full absolute top-0 z-10 bg-[#F98B2A] opacity-20"></div>
                    <h1 className='font-inter font-bold text-[60px] leading-[73px] text-white z-20  animate-slideUp ' >Our Products</h1>
                    <div className="h-auto w-[400px] border-t-[10px] border-[#F98B2A] animate-slideUp "></div>
                </div>
                <p className='font-inter font-bold text-[40px] text-center leading-[73px] text-[#F98B2A] z-20 mt-14 uppercase animate-slideUp  ' >légumes</p>
                <div className=" w-[80%] mx-auto h-fit mt-14 flex flex-wrap justify-center gap-x-4 gap-y-10 ">
                    {legumes.map((legume) => {
                        return (
                            <Link className="md:w-1/4 w-full " to={'/pr-info'}
                                state={
                                    {
                                        id: legume.Id,
                                        image: legume.image,
                                        nom: legume.nom,
                                        valid: legume.validity
                                    }
                                } >

                                <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                                    <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${legume.image})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                                    <div className=" w-full justify-center flex items-center flex-col ">
                                        <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " > {legume.nom} </p>
                                    </div>
                                </div>

                            </Link>
                        );
                    })}
                    {/* <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                        <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg1})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                        <div className=" w-full justify-center flex items-center flex-col ">
                            <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name produc</p>
                        </div>
                    </div>
                    <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                        <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg2})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                        <div className=" w-full justify-center flex items-center flex-col ">
                            <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                        </div>
                    </div>
                    <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                        <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg3})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                        <div className=" w-full justify-center flex items-center flex-col ">
                            <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                        </div>
                    </div> */}

                </div>
                <Link to={`/catgLegume`} className='transition-all duration-300 ease-in-out w-[230px] h-[60px] bg-gray-400 hover:bg-[#2BB673] rounded-[14px] flex items-center justify-center mt-14 mx-auto cursor-pointer ' >
                    <p className="text-[15px] font-bold leading-[36px] text-center text-[#FFFFFF] font-inter" >Discover more</p>
                </Link>

                <p className='font-inter font-bold text-[40px] text-center leading-[73px] text-[#F98B2A] z-20 mt-14 uppercase ' >Herbes aromatiques fraîches</p>
                <div className=" w-[80%] mx-auto h-fit mt-14 flex flex-wrap justify-center gap-x-4 gap-y-10 ">
                    {herbes.map((herbe) => {
                        return (
                            <Link className="md:w-1/4 w-full " to={'/pr-info'}
                                state={
                                    {
                                        id: herbe.Id,
                                        image: herbe.image,
                                        nom: herbe.nom,
                                        valid: herbe.validity
                                    }
                                } >

                                <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                                    <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${herbe.image})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                                    <div className=" w-full justify-center flex items-center flex-col ">
                                        <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " > {herbe.nom} </p>
                                    </div>
                                </div>

                            </Link>
                        );
                    })}
                    {/* <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                        <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg1})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                        <div className=" w-full justify-center flex items-center flex-col ">
                            <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                        </div>
                    </div>
                    <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                        <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg2})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                        <div className=" w-full justify-center flex items-center flex-col ">
                            <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                        </div>
                    </div>
                    <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                        <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg3})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                        <div className=" w-full justify-center flex items-center flex-col ">
                            <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                        </div>
                    </div> */}

                </div>
                <Link to={`/catgHerbes`} className=' transition-all duration-300 ease-in-out w-[230px] h-[60px] bg-gray-400 hover:bg-[#2BB673] rounded-[14px] flex items-center justify-center mt-14 mx-auto cursor-pointer ' >
                    <p className="text-[15px] font-bold leading-[36px] text-center text-[#FFFFFF] font-inter" >Discover more</p>
                </Link>
                <p className='font-inter font-bold text-[40px] text-center leading-[73px] text-[#F98B2A] z-20 mt-14 uppercase ' >Fruits</p>
                <div className=" w-[80%] mx-auto h-fit mt-14 flex flex-wrap justify-center gap-x-4 gap-y-10 ">

                    {fruits.map((fruit) => {
                        return (
                            <Link className="md:w-1/4 w-full " to={'/pr-info'}
                                state={
                                    {
                                        id: fruit.Id,
                                        image: fruit.image,
                                        nom: fruit.nom,
                                        valid: fruit.validity
                                    }
                                } >

                                <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                                    <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${fruit.image})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                                    <div className=" w-full justify-center flex items-center flex-col ">
                                        <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " > {fruit.nom} </p>
                                    </div>
                                </div>

                            </Link>
                        );
                    })}

                    {/* <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                        <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg1})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                        <div className=" w-full justify-center flex items-center flex-col ">
                            <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                        </div>
                    </div>
                    <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                        <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg2})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                        <div className=" w-full justify-center flex items-center flex-col ">
                            <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                        </div>
                    </div>
                    <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                        <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg3})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                        <div className=" w-full justify-center flex items-center flex-col ">
                            <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                        </div>
                    </div> */}
                </div>
                <Link to={`/catgFruit`} className='transition-all duration-300 ease-in-out w-[230px] h-[60px] bg-gray-400 hover:bg-[#2BB673] rounded-[14px] flex items-center justify-center mt-14 mx-auto cursor-pointer ' >
                    <p className="text-[15px] font-bold leading-[36px] text-center text-[#FFFFFF] font-inter" >Discover more</p>
                </Link>
            </div>
            <Footer />
        </div>
    );
}

export default AllProducts;