import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Dashboard from './Pages/Home/Dashboard';
// import Navbar from './Section/Navbar';
// import Produit from './Pages/Home/Produit';
import AllProducts from './Pages/Produit/AllProduct';
import Produitinfo from './Pages/Produit/ProduitInfo';
// import Allservices from './Pages/Services/Allservices';
import ServiceInfo from './Pages/Services/ServiceInfo';
import News from './Pages/News/News';
import Legume from './Pages/Produit/AllCategorieProduit/Legume';
import Fruit from './Pages/Produit/AllCategorieProduit/Fruits';
import Herbes from './Pages/Produit/AllCategorieProduit/Herbes';
import Hydroponie from './Pages/Services/Hydroponie';
import Compostage from './Pages/Services/Compostage';
import Agriculture from './Pages/Services/Agriculture';
import Contact from './Pages/Contact/Contact';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/pr" element={<AllProducts />} />
      <Route path="/pr-info" element={<Produitinfo />} />
      <Route path="/catgLegume" element={<Legume />} />
      <Route path="/catgFruit" element={<Fruit />} />
      <Route path="/catgHerbes" element={<Herbes />} />

      <Route path="/ser-Info" element={<ServiceInfo />} />
      <Route path="/Hydroponie" element={<Hydroponie />} />
      <Route path="/Compostage" element={<Compostage />} />
      <Route path="/Agriculture" element={<Agriculture />} />
      <Route path="/News/:id" element={<News />} />
      <Route path="/contact" element={<Contact />} />

      {/* <Route path="/serviceinfo/:category" element={<ServiceInfo />} /> */}
    </Routes>
  </Router>
);



