import React, { useEffect, useRef } from 'react';
import DashOne from '../../img/dashbord/apropos.jpg';
import DashTwo from '../../img/dashbord/produitsdd.jpg';
import DashThree from '../../img/dashbord/projetsdd.jpg';
import rectang from '../../img/Rectangle.png';
import { useMemo } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import { useGSAP } from '@gsap/react';
import { gsap } from 'gsap';

const Dashboard = () => {
    const aboutUsRef = useRef(null);
    const produitsRef = useRef(null);
    const projetsRef = useRef(null);
    const parentDivRef = useRef(null); // Ref for the parent div
    const recBackgrounds = useMemo(() => [DashOne, DashTwo, DashThree], []);
    const dashHead1 = useRef(null); // Ref for the first title
    const dashHead2 = useRef(null); // Ref for the first title
    const dashHead3 = useRef(null); // Ref for the first title
    // const titel2Ref = useRef(null); // Ref for the second title

    gsap.registerPlugin(ScrollTrigger); // Register the ScrollTrigger plugin
    
    useEffect(() => {
        const tel = gsap.timeline();
        const div1 = aboutUsRef.current;
        const div2 = produitsRef.current;
        const div3 = projetsRef.current;
        const dash1 = dashHead1.current;
        const dash2 = dashHead2.current;
        const dash3 = dashHead3.current;
        // const titel2Element = titel2Ref.current;

        // First animation for the first title (no scroll trigger, animate immediately)
        tel.fromTo(
            [div1, div2, div3],
            { opacity: 0, y: 100 },
            {
                opacity: 1, y: 0, duration: 1,
                stagger: {
                    // wrap advanced options in an object
                    each: 0.6,
                    from: 'top',
                    // grid: 'auto',
                    ease: 'power2.inOut',
                    // repeat: -1 // Repeats immediately, not waiting for the other staggered animations to finish
                }
            }
        );
        tel.fromTo(
            [dash1, dash2, dash3],
            { opacity: 0, x: 100 },
            {
                opacity: 1, x: 0, duration: 0.4,
                stagger: {
                    // wrap advanced options in an object
                    each: 0.2,

                    // grid: 'auto',
                    ease: 'power2.inOut',
                    // repeat: -1 // Repeats immediately, not waiting for the other staggered animations to finish
                }
            }
        )
        tel.fromTo(
            ".savoirPlus",
            { opacity: 0, x: 100 },
            {
                opacity: 1, x: 0, duration: 0.5,
                stagger: {
                    // wrap advanced options in an object
                    each: 0.2,

                    // grid: 'auto',
                    ease: 'power2.inOut',
                    // repeat: -1 // Repeats immediately, not waiting for the other staggered animations to finish
                }
            }
        )
    }, []);


    useEffect(() => {
        const parentDivElement = parentDivRef.current; // Get the parent div element
        const aboutUsElement = aboutUsRef.current; // Store ref value in a local variable
        const produitsElement = produitsRef.current; // Store ref value in a local variable
        const projetsElement = projetsRef.current; // Store ref value in a local variable
        // parentDivElement.style.transition = "all 0.6s ease-in-out";

        const handleMouseOver = (backgroundImage) => {
            if (parentDivElement) {
                parentDivElement.style.transition = "all 0.3s ease-in-out";
                parentDivElement.style.backgroundImage = `url(${backgroundImage})`; // Set the hovered background
                parentDivElement.style.backgroundSize = 'cover'; // Ensure the image covers the div
                parentDivElement.style.backgroundPosition = 'center'; // Center the image
            }

            // Clear backgrounds from other rec divs
            const recElements = document.querySelectorAll('.rec');
            recElements.forEach((element) => {
                element.style.backgroundImage = ''; // Clear background image
                element.style.borderRight = '5px solid white'; // Add white border right
            });
        };

        const handleMouseLeave = () => {
            // Reset parent background
            if (parentDivElement) {
                parentDivElement.style.backgroundImage = `url(${rectang})`;
                parentDivElement.style.transition = "";
            }

            // Restore backgrounds for rec divs
            const recElements = document.querySelectorAll('.rec');
            recElements.forEach((element, index) => {

                element.style.backgroundImage = `url(${recBackgrounds[index]})`; // Restore original background
                element.style.borderRight = 'none'; // Remove white border right
                element.style.backgroundSize = 'cover'; // Ensure the image covers the div
                element.style.backgroundPosition = 'center'; // Center the image
            });
        };

        const isMobile = () => window.innerWidth <= 768;

        if (!isMobile()) {
            // Add event listeners for mouseover on specific sections
            if (aboutUsElement) {
                aboutUsElement.addEventListener("mouseover", () => {
                    handleMouseOver(DashOne);
                    document.getElementById("line1").style.transition = "width 0.6s ease-in-out";
                    document.getElementById("InfoAboutus").style.transition = "translate 0.6s ease-in-out";
                    document.getElementById("InfoAboutus").style.transform = "translate-y-0";
                    document.getElementById("line1").style.width = '0';
                    document.getElementById("InfoAboutus").classList.add("visible");
                    document.getElementById("AboutusDiscover").style.display = 'none';
                });
                aboutUsElement.addEventListener("mouseleave", () => {
                    handleMouseLeave();
                    document.getElementById("line1").style.transition = "width 0.6s ease-in-out";
                    document.getElementById("line1").style.width = '9rem';
                    document.getElementById("InfoAboutus").classList.remove("visible");
                    document.getElementById("AboutusDiscover").style.display = 'flex';
                });
            }

            if (produitsElement) {
                produitsElement.addEventListener("mouseover", () => {
                    handleMouseOver(DashTwo);
                    document.getElementById("line2").style.transition = "width 0.6s ease-in-out";
                    document.getElementById("InfoProduit").style.transition = "translate 0.6s ease-in-out";
                    document.getElementById("InfoProduit").style.transform = "translate-y-0";
                    document.getElementById("line2").style.width = '0';
                    document.getElementById("InfoProduit").classList.add("visible");
                    document.getElementById("ProduitDiscover").style.display = 'none';
                });
                produitsElement.addEventListener("mouseleave", () => {
                    handleMouseLeave();
                    document.getElementById("line2").style.transition = "width 0.6s ease-in-out";
                    document.getElementById("line2").style.width = '9rem';
                    document.getElementById("InfoProduit").classList.remove("visible");
                    document.getElementById("ProduitDiscover").style.display = 'flex';
                });
            }

            if (projetsElement) {
                projetsElement.addEventListener("mouseover", () => {
                    handleMouseOver(DashThree);
                    document.getElementById("line3").style.transition = "width 0.6s ease-in-out";
                    document.getElementById("InfoProjet").style.transition = "translate 0.6s ease-in-out";
                    document.getElementById("InfoProjet").style.transform = "translate-y-0";
                    document.getElementById("line3").style.width = '0';
                    document.getElementById("InfoProjet").classList.add("visible");
                    document.getElementById("ProjetDiscover").style.display = 'none';
                });
                projetsElement.addEventListener("mouseleave", () => {
                    handleMouseLeave();
                    document.getElementById("line3").style.transition = "width 0.6s ease-in-out";
                    document.getElementById("line3").style.width = '9rem';
                    document.getElementById("InfoProjet").classList.remove("visible");
                    document.getElementById("ProjetDiscover").style.display = 'flex';
                });
            }
        }

        // Cleanup event listeners on component unmount
        return () => {
            if (aboutUsElement) {
                aboutUsElement.removeEventListener("mouseover", () => {
                    handleMouseOver(DashOne);
                });
                aboutUsElement.removeEventListener("mouseleave", handleMouseLeave);
            }
            if (produitsElement) {
                produitsElement.removeEventListener("mouseover", () => handleMouseOver(DashTwo));
                produitsElement.removeEventListener("mouseleave", handleMouseLeave);
            }
            if (projetsElement) {
                projetsElement.removeEventListener("mouseover", () => handleMouseOver(DashThree));
                projetsElement.removeEventListener("mouseleave", handleMouseLeave);
            }
        };
    }, [recBackgrounds]); // Add recBackgrounds as a dependency

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    return (
        <div
            ref={parentDivRef}
            className="all 0.6s ease-in-out w-full h-[90vh] bg-white grid grid-cols-1 gap-y-5  md:grid-cols-3 gap-[2px] relative slideUp mt-16 "
        >
            <div ref={aboutUsRef} id='' className="rec h-full bg-cover relative  " style={{ backgroundImage: `url(${DashOne})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="w-full h-full absolute top-0 z-10 bg-[#F98B2A] opacity-20"></div>
                <div className="absolute bottom-2 md:bottom-[4rem] left-[1rem] md:left-[2rem] z-20">
                    <a ref={dashHead1} className='font-Inter md:text-[50px] md:mb-[20px] mb-0 text-[40px]  text-white font-bold cursor-pointer ' href="/#Apropo" >A propos de nous </a>
                    <p id='AboutusDiscover' className='visible font-Inter md:text-[20px] text-[15px] text-white font-light md:mt-[-15px] mt-[0px] opacity-100 savoirPlus '>En savoir plus</p>
                    <p id='InfoAboutus' className=' all 0.6s ease-in-out  hidden font-Inter text-[20px] text-white font-bold  ' >Maroharvest s'engage à offrir des produits agricoles de haute qualité, issus des meilleures récoltes marocaines</p>
                    <div id='line1' className=" bg-white w-[9rem] h-[2px] transition-width duration-600 ease-in-out savoirPlus "></div>
                </div>
            </div>
            <div ref={produitsRef} className="rec h-full bg-cover relative  " style={{ backgroundImage: `url(${DashTwo})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="w-full h-full absolute top-0 z-10 bg-[#F98B2A] opacity-20"></div>
                <div className="absolute bottom-2 md:bottom-[4rem] left-[1rem] md:left-[2rem] z-20">
                    <a ref={dashHead2} className='font-Inter md:text-[50px] md:mb-[20px] mb-0 text-[40px] text-white font-bold' href="/#Pr">Produits</a>
                    <p id='ProduitDiscover' className='visible font-Inter md:text-[20px] text-[15px] text-white font-light md:mt-[-15px] mt-[0px] opacity-100 savoirPlus ' >En savoir plus</p>
                    <p id='InfoProduit' className='all 0.6s ease-in-out  hidden font-Inter text-[20px] text-white font-bold  ' >Découvrez notre gamme de fruits et légumes frais, cultivés avec soin dans les terres fertiles du Maroc.</p>
                    <div id='line2' className=" bg-white w-[9rem] h-[2px] transition-width duration-600 ease-in-out savoirPlus "></div>
                </div>
            </div>
            <div ref={projetsRef} className="rec h-full bg-cover relative " style={{ backgroundImage: `url(${DashThree})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="w-full h-full absolute top-0 z-10 bg-[#F98B2A] opacity-20"></div>
                <div className="absolute bottom-2 md:bottom-[4rem] left-[1rem] md:left-[2rem] z-20">
                    <a ref={dashHead3} className='font-Inter md:text-[50px] md:mb-[20px] mb-0 text-[40px] text-white font-bold' href="/#Ser" >Projets</a>
                    <p id='ProjetDiscover' className='visible font-Inter md:text-[20px] text-[15px] text-white font-light md:mt-[-15px] mt-[0px] opacity-100 savoirPlus '>En savoir plus</p>
                    <p id='InfoProjet' className='all 0.6s ease-in-out  hidden font-Inter text-[20px] text-white font-bold  ' >Nous croyons en l'agrinnovation pour un avenir durable. Maroharvest soutient des initiatives pour une agriculture novatrice</p>
                    <div id='line3' className=" bg-white w-[9rem] h-[2px] transition-width duration-600 ease-in-out savoirPlus "></div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
