import Footer from "../../Section/Footer";
import Navbar from "../../Section/Navbar";
import back from '../../img/services.jpg';
import leg1 from '../../img/leg1.jpg';
import leg2 from '../../img/leg2.jpg';
import leg3 from '../../img/leg3.jpg';
import { Link } from 'react-router-dom';
import { useState } from 'react';
const ServiceInfo = () => {
    const [selectedCountry, setSelectedCountry] = useState('Pays');
    const [isOpen, setIsOpen] = useState(false);

    const countries = ['France', 'Spain', 'Germany', 'Italy', 'Morroco'];

    const handleCountryClick = (country) => {
        setSelectedCountry(country);
        setIsOpen(false);
    };
    return (
        <div className=" flex flex-col ">
            <Navbar />
            <div className="z-10  ">
                <div className="w-full h-[550px] relative flex items-center justify-center flex-col  " style={{ backgroundImage: `url(${back})`, backgroundSize: 'cover', backgroundPosition: 'center' }} >
                    <div className="w-full h-full absolute top-0 z-10 bg-[#F98B2A] opacity-20"></div>
                    <h1 className='font-inter font-bold text-[60px] leading-[73px] text-white z-20 ' >Nom Service</h1>
                    <div className="h-auto w-[400px] border-t-[10px] border-[#F98B2A]"></div>
                </div>
                <p className="mt-5 text-center text-[15px] md:text-[31.9202px] font-Inter font-bold px-3 md:px-0  md:leading-[39px] text-[#F98B2A] max-w-[1280px] mx-auto " >Maroharvest, une marque de Chakkor Trading Company, <br /> est spécialisée dans l'exportation de fruits et légumes frais, soigneusement sélectionnés pour leur qualité et leur fraîcheur. Nous proposons une large gamme de produits agricoles, notamment des agrumes, légumes et herbes aromatiques cultivés dans les meilleures conditions au Maroc.
                    Grâce à notre expertise et à un réseau de distribution bien établi, nous répondons aux besoins des marchés internationaux, en particulier en Afrique, avec des produits respectant les normes de qualité mondiales.
                    Notre mission est d'assurer à nos clients des produits qui se distinguent par leur goût, leur fraîcheur et leur qualité nutritionnelle, en établissant des relations solides avec nos partenaires à travers le monde, tout en respectant des pratiques agricoles durables.
                </p>
                <p className='font-inter font-bold text-[40px] text-center leading-[73px] text-[#F98B2A] z-20 mt-14 uppercase ' >légumes</p>
                <div className=" w-[80%] mx-auto h-fit mt-14 flex flex-wrap justify-center gap-x-4 gap-y-10 ">
                    <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                        <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg1})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                        <div className=" w-full justify-center flex items-center flex-col ">
                            <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                        </div>
                    </div>
                    <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                        <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg2})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                        <div className=" w-full justify-center flex items-center flex-col ">
                            <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                        </div>
                    </div>
                    <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                        <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg3})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                        <div className=" w-full justify-center flex items-center flex-col ">
                            <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                        </div>
                    </div>

                </div>
                <Link to={`/pr`} className='transition-all duration-300 ease-in-out w-[230px] h-[60px] bg-gray-400 hover:bg-[#2BB673] rounded-[14px] flex items-center justify-center mt-14 mx-auto cursor-pointer ' >
                    <p className="text-[15px] font-bold leading-[36px] text-center text-[#FFFFFF] font-inter" >Discover more</p>
                </Link>
            </div>
            <div className=" mx-auto mt-5 md:w-[658px] w-[460px] h-[516px] rounded-[35px] shadaw-contact flex flex-col items-center py-7 justify-between " >
                <div className="md:w-[587px] w-[430px] h-[33px]  flex justify-between items-center">
                    <input type="text" className='w-[190px] md:w-[258px] h-[33px] border-b-4 border-[#2BB673] outline-none' placeholder='Nom complet' />
                    <input type="text" className='w-[190px] md:w-[258px] h-[33px] border-b-4 border-[#2BB673] outline-none' placeholder='Adresse email' />
                </div>
                <div className=" md:w-[587px] w-[430px] border-b-4 border-[#2BB673] flex justify-start items-center cursor-not-allowed ">
                    <p className='font-inter font-light text-[20px] leading-[24px] text-black' >message gdeygdyegdygeydgyegdyegytt</p>
                </div>
                <div className=" md:w-[587px] w-[430px] h-[205px] shadow-md rounded-[10px] flex justify-start items-center overflow-hidden ">
                    <textarea name="" id="" className=' w-full h-full p-5 outline-none resize-none font-inter font-light text-[20px] leading-[24px] text-black ' >
                        {`
Bonjour,

Nous sommes intéressés par votre produit “PASTEQUE (Product code 48)”
pourriez-vous s'il vous plaît nous envoyer votre offre en conséquence.

Merci Cordialement
                       `}
                    </textarea>
                </div>
                <div className=" md:w-[587px] w-[430px] h-[47.38px] flex justify-between ">
                    <div>
                        <div
                            className="w-[188px] h-[47.38px] bg-[#F98B2A] rounded-[7.06px] flex justify-center items-center cursor-pointer "
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <p className='font-inter font-bold text-[15.12px] leading-[18px] text-center text-white'>{selectedCountry}</p>
                        </div>
                        {isOpen && (
                            <div className="absolute z-40 bg-white border border-gray-300 mt-1 rounded shadow">
                                {countries.map((country, index) => (
                                    <div
                                        key={index}
                                        className="p-2 cursor-pointer hover:bg-gray-200"
                                        onClick={() => handleCountryClick(country)}
                                    >
                                        {country}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="w-[188px] h-[47.38px] bg-[#2BB673] rounded-[7.06px] flex justify-center items-center cursor-pointer  ">
                        <p className='font-inter font-bold text-[15.12px] leading-[18px] text-center text-white' >Envoyer</p>
                    </div>
                </div>

            </div>
            <Footer/>
        </div>
    );
}

export default ServiceInfo;