import { useLocation } from "react-router-dom";
import Footer from "../../Section/Footer";
import Navbar from "../../Section/Navbar";
import { useEffect } from "react";

const News = () => {
    // const { id } = useParams();
    const location = useLocation();
    const { title, image ,text2 } = location.state || {};
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="">
            <Navbar />
            <div className="">
                <div className=" md:px-0 px-3 mt-8 md:mt-[83px] w-full flex flex-col md:flex-row  justify-between items-center max-w-[1280px] mx-auto "  >
                    <div className="w-[455px] md:w-[612px] h-[360px] bg-black  " style={{ backgroundImage: `url(${image})`, backgroundSize: 'cover', backgroundPosition: 'center' }} >
                    </div>
                    <div className="max-w-[612px] h-fit  ">
                        <p className="md:text-[40px] text-[30px] md:ml-5 font-bold text-start text-[#218555] font-inter underline" >{title}</p>
                        {/* <p className="md:text-[20px] font-normal text-[14px] leading-[17px] mb-5 mt-5 md:mt-0 md:mb-0 md:leading-[24px] text-black font-inter" > {text} </p> */}
                        {/* <p className="md:text-[20px] font-normal text-[14px] leading-[17px] mb-5 mt-5 md:mt-0 md:mb-0 md:leading-[24px] text-black font-inter" >Maroharvest s'engage à exporter ses produits frais vers plusieurs marchés internationaux. Nous livrons principalement en Afrique, mais également en Europe, notamment en France, en Espagne, et en Angleterre, ainsi qu'aux pays du Golfe</p> */}
                    </div>
                </div>
                {/* <div className=" md:px-0 px-3 mt-8 md:mt-[83px] w-full flex flex-col md:flex-row  justify-between items-center max-w-[1280px] mx-auto "  >
                    <div className="w-[455px] md:w-[612px] h-[360px] bg-black  ">

                    </div>
                    <div className="max-w-[612px] max-h-[220px]  ">
                        <p className="md:text-[40px] text-[30px] font-bold text-start text-black font-inter" >Du Maroc au monde : Nos zones de distribution</p>
                        <p className="md:text-[20px] font-normal text-[14px] leading-[17px] mb-5 mt-5 md:mt-0 md:mb-0 md:leading-[24px] text-black font-inter" >Maroharvest s'engage à exporter ses produits frais vers plusieurs marchés internationaux. Nous livrons principalement en Afrique, mais également en Europe, notamment en France, en Espagne, et en Angleterre, ainsi qu'aux pays du Golfe</p>
                    </div>

                </div> */}
            </div>
            <p className="md:text-[20px] font-normal ml-2  text-[14px] leading-[17px] mb-5 mt-5 md:mb-0 md:leading-[24px] max-w-[1280px] mx-auto md:ml-[5.25rem] md:mt-10 text-black font-inter" > {text2} </p>
            <p className="md:text-[30px] font-Poppins font-bold uppercase text-center  text-[14px] leading-[17px] mb-5 mt-5 md:mb-[6.5rem] md:leading-[35px] max-w-[1280px] mx-auto md:mt-[6.5rem] text-black font-inter animate-slideUp " > Pour découvrir plus d'actualités, consultez notre section <a href="/" className=" font-black text-[#218555] underline "  >MaroNews</a> et suivez-nous sur <a href="https://www.instagram.com/maroharvest/?utm_source=ig_web_button_share_sheet" className="underline  gradient-text "  >Instagram</a> et <a href="https://www.linkedin.com/company/chakkor-trading-company/" className=" font-black text-[#5681b0]  "  >LinkedIn</a> </p>
            <Footer />
        </div>
    );
}

export default News;