// import leg1 from '../../img/leg1.jpg';
import logo from '../../img/dispo.png';
import Footer from '../../Section/Footer';
import { useEffect} from 'react';
import Navbar from '../../Section/Navbar';
import { useLocation } from 'react-router-dom';

import Contact from '../../Section/Contact';

// const mounth = [];

const Produitinfo = () => {
    

    const location = useLocation();
    const { id, image, nom, valid , cat } = location.state || {};

    // const countries = ['France', 'Spain', 'Germany', 'Italy', 'Morroco'];

    

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className=" w-full justify-center flex flex-col items-center  ">
            <Navbar />
            <div className=" w-full md:w-[1110px] h-fit md:h-[466px]  mt-7 flex md:flex-row flex-col  justify-between items-center ">
                <div className="w-full h-fit bg-white flex justify-center items-center"  >
                    <img src={image} className='  h-auto w-full m-auto' alt="" />
                </div>
                <div className=" w-full  md:w-[619px] h-fit md:h-[328px] flex flex-col justify-center ">
                    <p className="font-inter font-bold text-[40px] leading-[48px] text-[#218555] ml-2" > {nom} </p>
                    <p className="font-inter font-semibold md:text-[20px] text-[20px] md:leading-[36px] leading-[30px] mt-5 text-[#000000] ml-2" > <b>Catégorie:</b> { cat } </p>
                    <p className="font-inter font-semibold md:text-[15px] text-[15px] md:leading-[26px] leading-[30px] mt-1 text-[#000] ml-2" > <b className='text-black' >Référence produit:</b> { id } </p>
                </div>
            </div>
            <div className="visible md:hidden w-full md:w-[70%] mb-16 h-fit p-5 grid grid-cols-3   gap-y-1 border shadow md:mb-0 mt-10 bg-white ">
                {valid.map((val) => {
                    return (   
                        <div className={` h-full flex flex-col justify-between items-center py-1 border  ${val.valid ? ' bg-[#218555] shadow text-[#fff]' : ' bg-white text-[#000000]'} `}>
                            <p className='font-inter font-bold md:text-[20px] text-[15px] leading-[15px] mb-3 mt-3 md:mb-5 md:mt-5 ' >{val.mounth}</p>
                            <div className={` w-full h-0 border-[2px] ${val.valid === true ? 'border-white' : 'border-gray-600'} `}></div>
                            <div className={` md:w-[25px] md:h-[25px] w-[22px] h-[22px] mt-3 mb-3 md:mb-5 md:mt-5 ${val.valid ? '' : ' mix-blend-luminosity '} `} style={{ backgroundImage: `url(${logo})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                        </div>
                    );
                })}
            </div>
            <div className="hidden md:grid w-full md:h-[130px] h-[100px] p-5  grid-cols-12   gap-y-5  md:mb-0 mt-10 bg-white ">
                {valid.map((val) => {
                    return (   
                        <div className="  h-full flex flex-col justify-between items-center py-1   ">
                            <p className='font-inter font-bold md:text-[20px] text-[5px] leading-[15px] mb-2 mt-2 text-[#000000]' >{val.mounth}</p>
                            <div className={` w-full h-0 border-[2px] ${val.valid === true ? 'border-[#2BB673]' : 'border-gray-600'} `}></div>
                            <div className={` md:w-[25px] md:h-[25px] w-[12px] h-[12px] mt-5 mb-5 ${val.valid ? '' : ' mix-blend-luminosity '} `} style={{ backgroundImage: `url(${logo})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                        </div>
                    );
                })}
            </div>
            <Contact nom={nom} id={id} />
            <Footer />
        </div>
    );
}

export default Produitinfo;